<template>
  <div class="cuotiben_Warp">
    <div class="top">
      <div class="del"
           v-if="!showCheck"
           @click="del">编辑</div>
      <div v-else
           style="display:flex">
        <div class="del"
             @click="change">取消</div>

        <div class="del"
             style="background:#f3636e;margin-left:10rem"
             @click="del">删除</div>
      </div>
    </div>

    <COMMONTOPSEARCH ref="commonTopSearchRef"
                     @initData="getPaperList"
                     background="#303FD4" />

    <div class="topic_warp">
      <div class="papers"
           id="papers">
        <div class="item_wrap"
             v-for="(item,index) in paperList"
             :key="index">
          <div class="paper"
               :style="{'background':backgrounds[Math.floor(index/5)%7]}">
            <div class="checked"
                 :style="{'border':  checkIds.indexOf(item.user_paper_id) != -1 ?'none':''}"
                 v-if="showCheck"
                 @click="doPaper(item)">
              <img src="@/static/cuotiben/chcked.png"
                   v-if="checkIds.indexOf(item.user_paper_id) != -1"
                   alt="">
            </div>
            <div class="tag">
              {{item.module_name}}
            </div>
            <div class="content"
                 :style="{'color':textColor[Math.floor(index/5)%7]}">
              {{item.source_name}}
            </div>
            <div class="btn"
                 @click="doPaper(item)">继续做卷</div>
            <div class="time"
                 :style="{'color':textColor[Math.floor(index/5)%7]}">
              {{item.paper_time}}
            </div>
          </div>
        </div>
        <div class="item_wrap"
             style="height:1px"></div>
        <div class="item_wrap"
             style="height:1px"></div>

        <div class="item_wrap"
             style="height:1px"></div>

        <div class="item_wrap"
             style="height:1px"></div>

      </div>
    </div>
  </div>
</template>

<script setup>
import COMMONTOPSEARCH from '@/views/components/COMMON_TOP_SEARCH/index.vue'
import { ref } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import { getList, delRow } from '@/api/errorBook.js'
import { getLearnProcessList, delPrgoressPaper } from '@/api/learnProcess.js'
import { message } from 'ant-design-vue'

const route = useRoute()
const router = useRouter()

const commonTopSearchRef = ref(null)
// let showTag = ref(true)
let paperList = ref([])
let checkIds = ref([])
let showCheck = ref(false)
let subject_id = ref('')
let backgrounds = ['#D2DCF9', '#D2F9F4', '#F9F1D2', '#D2ECF9', '#F9DED2', '#DED2F9', '#F9D2D2']
let textColor = ['#0D2878', '#0D7866', '#784B0D', '#0D2C78', '#78390D', '#2B0D78', '#780D0D']
const getPaperList = async (id, keywords) => {
  let params = {
    subject_id: id,
    keywords: keywords,
    status: 5
  }
  subject_id.value = id
  const { data } = await getLearnProcessList(params)
  paperList.value = Object.values(data.list)
  document.getElementById('papers').scrollTop = 0
}

const del = async () => {
  if (checkIds.value.length == 0) {
    change()
  } else {
    await delPrgoressPaper({ user_paper_id: checkIds.value.join(',') })
    message.success('删除成功')
    commonTopSearchRef.value.refresh()
    change()
  }
}

const change = () => {
  showCheck.value = !showCheck.value
  checkIds.value = []
}

const doPaper = (item) => {
  if (showCheck.value) {
    if (checkIds.value.indexOf(item.user_paper_id) == -1) {
      checkIds.value.push(item.user_paper_id)
    } else {
      checkIds.value.splice(checkIds.value.indexOf(item.user_paper_id), 1)
    }
  } else {
    let session_user_paper_id = window.sessionStorage.getItem('user_paper_id')
    if (item.user_paper_id != session_user_paper_id) {
      window.sessionStorage.removeItem('testpaperCardIndex')
      window.sessionStorage.removeItem('paper')
      window.sessionStorage.removeItem('images')
    }

    router.push('/doPaper?user_paper_id=' + item.user_paper_id)
  }
}
</script>

<style lang="scss" scoped>
.cuotiben_Warp {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  background-image: url("../../static/allBackg/bg-学习进度.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .top {
    position: absolute;
    width: 150px;
    margin-top: 20px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .papers {
    &::-webkit-scrollbar-thumb {
      background-color: #ffffff52;
    }
    margin-top: 30rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: calc(100vh - 269rem);
    padding-left: 40rem;
    overflow: scroll;
    // overflow: scroll;
    .item_wrap {
      width: 328rem;
      height: 310rem;
      padding: 0 10rem;
      margin: 0 auto 45rem;
    }
    .paper {
      position: relative;
      background: #d2dcf9;
      padding: 1px 0;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.09);
      border-radius: 15px;
      .checked {
        position: absolute;
        right: 17px;
        top: 20rem;
        width: 30rem;
        height: 30rem;
        border-radius: 50%;
        border: 1px solid #bbbbbb;
        cursor: pointer;
        img {
          width: 30rem;
          height: 30rem;
          transform: rotate(12deg);
        }
      }

      .tag {
        position: absolute;
        min-width: 150rem;
        // height: 44rem;
        top: 10rem;
        padding: 6rem 30rem 10rem;
        left: -10rem;
        background-image: url("../../static/center/tag.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22rem;
        font-weight: bold;
        color: #ffffff;
      }
      .content {
        margin-top: 70rem;
        height: 136rem;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        font-size: 24rem;
        line-height: 33rem;
        font-weight: bold;
        padding: 0 10rem;
      }
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22rem;
        font-weight: bold;
        color: #000000;
        background-color: #ffffff;
        margin: 0 auto;
        width: 130rem;
        height: 44rem;
        border-radius: 40rem;
        margin-top: 9rem;
        cursor: pointer;
      }
      .time {
        font-size: 20rem;
        font-weight: 500;
        color: #0d2878;
        text-align: center;
        margin-top: 10rem;
      }
    }
  }
}

.del {
  width: 150rem;
  height: 60rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 32rem;
  border-radius: 35rem;
  background: #303fd4;
  cursor: pointer;
}
.active {
  color: white;
  font-weight: bold;
}
</style>